var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "page-header"
  }, [_c('div', {
    staticClass: "container p-2"
  }, [_c('swiper', {
    staticClass: "swiper-autoplay",
    attrs: {
      "options": _vm.swiperOptions,
      "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'
    }
  }, _vm._l(_vm.swiperData, function (data) {
    return _c('swiper-slide', {
      key: data.img
    }, [_c('b-img', {
      staticClass: "img-fluid custom-swiper-img",
      staticStyle: {
        "width": "90%",
        "height": "370px",
        "object-fit": "cover",
        "border-radius": "15px"
      },
      attrs: {
        "src": "https://myskill.id/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fmain.3d8b87dd.png&w=1920&q=75"
      }
    })], 1);
  }), 1)], 1), _c('h4', {
    staticClass: "text-center mt-2"
  }, [_vm._v(" Raih Sukses Akademis Bersama Kami ")]), _vm._m(0)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container",
    staticStyle: {
      "padding-left": "6vw",
      "padding-right": "6vw",
      "margin-top": "3rem",
      "margin-bottom": "1.5rem"
    }
  }, [_c('div', {
    staticClass: "row g-4"
  }, [_c('div', {
    staticClass: "col-12 col-md-6 col-lg-3 mb-1"
  }, [_c('div', {
    staticClass: "card custom-card"
  }, [_c('div', {
    staticClass: "d-flex justify-content-center align-items-center"
  }, [_c('i', {
    staticClass: "fa-solid fa-graduation-cap custom-icon mt-2",
    staticStyle: {
      "color": "#003366"
    }
  })]), _c('div', {
    staticClass: "mt-3 text-center"
  }, [_c('h5', {
    staticClass: "text-muted"
  }, [_c('i', {
    staticClass: "d-block text-black font-weight-bold mb-1"
  }, [_c('b', [_vm._v("Lebih dari 100+")])]), _c('div', {
    staticStyle: {
      "font-size": "1.1rem",
      "font-weight": "normal"
    }
  }, [_vm._v("Siswa Menghadapi Ujian dengan Percaya Diri")])])])])]), _c('div', {
    staticClass: "col-12 col-md-6 col-lg-3 mb-1"
  }, [_c('div', {
    staticClass: "card custom-card"
  }, [_c('div', {
    staticClass: "d-flex justify-content-center align-items-center"
  }, [_c('i', {
    staticClass: "fa-solid fa-book custom-icon mt-2",
    staticStyle: {
      "color": "#6F4F28"
    }
  })]), _c('div', {
    staticClass: "mt-3 text-center"
  }, [_c('h5', {
    staticClass: "text-muted"
  }, [_c('i', {
    staticClass: "d-block text-black font-weight-bold mb-1"
  }, [_c('b', [_vm._v("Persiapan Lengkap")])]), _c('div', {
    staticStyle: {
      "font-size": "1.1rem",
      "font-weight": "normal"
    }
  }, [_vm._v("Untuk UH, PTS, PAS, US, SNBP, SNBT, dan Kedinasan ")])])])])]), _c('div', {
    staticClass: "col-12 col-md-6 col-lg-3 mb-1"
  }, [_c('div', {
    staticClass: "card custom-card"
  }, [_c('div', {
    staticClass: "d-flex justify-content-center align-items-center"
  }, [_c('i', {
    staticClass: "fa-solid fa-star custom-icon mt-2",
    staticStyle: {
      "color": "#FFD700"
    }
  })]), _c('div', {
    staticClass: "mt-3 text-center"
  }, [_c('h5', {
    staticClass: "text-muted"
  }, [_c('i', {
    staticClass: "d-block text-black font-weight-bold mb-1"
  }, [_c('b', [_vm._v("Metode Terbukti")])]), _c('div', {
    staticStyle: {
      "font-size": "1.1rem",
      "font-weight": "normal"
    }
  }, [_vm._v("Tutor Profesional, Fasilitas Terbaik, dan Biaya Terjangkau")])])])])]), _c('div', {
    staticClass: "col-12 col-md-6 col-lg-3 mb-1",
    staticStyle: {
      "margin-bottom": "90px"
    }
  }, [_c('div', {
    staticClass: "card custom-card"
  }, [_c('div', {
    staticClass: "d-flex justify-content-center align-items-center"
  }, [_c('i', {
    staticClass: "fa-solid fa-arrows-down-to-people custom-icon mt-2",
    staticStyle: {
      "color": "#FF0000"
    }
  })]), _c('div', {
    staticClass: "mt-3 text-center"
  }, [_c('h5', {
    staticClass: "text-muted"
  }, [_c('i', {
    staticClass: "d-block text-black font-weight-bold mb-1"
  }, [_c('b', [_vm._v("Lebih dari 90%")])]), _c('div', {
    staticStyle: {
      "font-size": "1.1rem",
      "font-weight": "normal"
    }
  }, [_vm._v("Bergabunglah dengan kami dan capai tujuan akademis Anda")])])])])])])]);
}]

export { render, staticRenderFns }