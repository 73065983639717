<template>
  <div class="background">
    <section id="card-images">
    <br />
    <h3 class="mt-3 mb-2 text-primary" align="center" text-color="primary">
      <b>Kata Mereka</b>
    </h3>
    <center>
      <h4 align="center" text-color="primary" class="mb-2" style="width: 80%">
        Ulasan dari Para Alumni yang Sukses Setelah Mengikuti Try Out di {{ appShortName }}
      </h4>
    </center>

    <br />
    <center>
      <div class="" style="width: 85%">
        <swiper
          class="swiper-responsive-breakpoints"
          :options="swiperOptions"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        >
          <swiper-slide v-for="(testimony, index) in testimonies" :key="index">
            <b-card>
              <b-card-body>
                <center>
                  <div>
                    <img
                      v-lazy="`/testimoni/${index}.${testimony.image_ext}`"
                      alt="Photo"
                      style="width: 100px; height: 100px"
                      class="mb-1 rounded-circle"
                    />
                  </div>
                  <h6 class="font-weight-bolder mb-1 text-center">
                    {{ testimony.name }}
                  </h6>
                  <span class="">{{ testimony.school_origin }}</span>
                </center>

                <section class="text-center mt-2">
                  <strong>{{ testimony.achievement }}</strong>
                </section>
                <section class="d-flex justify-content-center">
                  <b-form-rating
                    value="5"
                    no-border
                    readonly
                    variant="warning"
                    inline
                    size="sm"
                  />
                </section>

                <b-card-text class="text-center">
                  "{{ testimony.comment }}"
                </b-card-text>
              </b-card-body>
            </b-card>
          </swiper-slide>
          <div slot="pagination" class="swiper-pagination mt-4" />
        </swiper>
      </div>
    </center>
  </section>
  </div>

</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { BImg } from "bootstrap-vue";
import "swiper/css/swiper.css";
import { codeResponsive } from "./code";
import {
  BContainer,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardTitle,
  BCardText,
  BCardGroup,
  BButton,
  BCarousel,
  BCarouselSlide,
  BMedia,
  BMediaAside,
  BMediaBody,
  BAvatar,
  BFormRating,
} from "bootstrap-vue";

export default {
  components: {
    Swiper,
    SwiperSlide,
    BCardCode,
    BImg,
    BContainer,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardTitle,
    BCardText,
    BCardGroup,
    BButton,
    BCarousel,
    BCarouselSlide,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BFormRating,
  },
  data() {
    return {
      codeResponsive,

      /* eslint-disable global-require */
      swiperData: [
        { img: require("@/assets/images/banner/banner-30.jpg") },
        { img: require("@/assets/images/banner/banner-31.jpg") },
        { img: require("@/assets/images/banner/banner-32.jpg") },
        { img: require("@/assets/images/banner/banner-33.jpg") },
        { img: require("@/assets/images/banner/banner-34.jpg") },
        { img: require("@/assets/images/banner/banner-35.jpg") },
      ],
      /* eslint-disable global-require */
      testimonies: [
        {
          image_ext: "jpg",
          name: "DODI PRASETYA",
          school_origin: "SMAN 2 BKL",
          achievement: "KEDOKTERAN - UNJA",
          comment:
            "Senang bisa gabung di ELP, makasih buat ELP, makasih buat tutornya yang okee.. jangan pernah bosan buat kita tambah pintar dan juara ya",
        },
        {
          image_ext: "jpg",
          name: "M. ARKOUN",
          school_origin: "SMAN 7 BKL",
          achievement: "KEDOKTERAN - UNBRAW",
          comment:
            "Les di ELP itu ASYIIKK.. pengajarnya ramah dan sabar saat menjelaskan matei. Jadi makin SEMANGAT kalau belajar di ELP",
        },
        {
          image_ext: "jpg",
          name: "YUYUN A.",
          school_origin: "SMAN 5 BKL",
          achievement: "DOKTER GIGI - UNBRAW",
          comment:
            "Walaupun banyak kesibukan, berkat ELP saya gak pernah ketinggalan materi di sekolah, Terimakasih ELP sudah menjadi partner terbaikku meraih nilai bagus di sekolah",
        },
        {
          image_ext: "jpg",
          name: "ZELLA RISKI F",
          school_origin: "SMAN 6 BKL",
          achievement: "PEND. DOKTER - UNIB",
          comment:
            "Masa belajar paling menyenangkan selama bersekolah… ya ELP. Karena disini saya mendapatkan banyak ilmu yang baru. Semoga ELP sukses selalu dan menciptakan anak2 bangsa yang hebat",
        },
        {
          image_ext: "jpg",
          name: "TANIA CIPTA S",
          school_origin: "SMAN 5 BKL",
          achievement: "KEDOKTERAN GIGI - UNAND",
          comment:
            "ELP itu tempatnya orang-orang yang seru terbukti nilaiku di sekolah meningkat. Pokoknya di ELP itu asyiiiik bangett deh.. pokoknya maju terus buat ELP",
        },
        {
          image_ext: "jpg",
          name: "PUTRI KAMILAH",
          school_origin: "SMAN 8 BKL",
          achievement: "PEND. DOKTER HEWAN - USK",
          comment:
            "Sukses selalu ya ELP, ELP sangat membantu saya mendapatkan nilai-nilai yang bagus serta PTN yang saya inginkan. Terimakasih ELP",
        },
        {
          image_ext: "jpg",
          name: "SHELLY MARSHANDA",
          school_origin: "SMAN 7 BKL",
          achievement: "PEND. DOKTER - UNIB",
          comment:
            "Belajar di ELP itu mantap bangeeet, bayangin aja ilmu yang cuma 1 bulan bisa bertahan 1 tahun setelahnya terus bisa lulus di kedokteran lagi, belajar di ELP itu solusi terbaik",
        },
        {
          image_ext: "jpg",
          name: "ALENA BRILIANTYAS S",
          school_origin: "SMAN 2 BKL",
          achievement: "PEND. DOKTER - UNSRI",
          comment:
            "ELP itu tempat saya bercapek2. ELP juga motivasi dan inspirasi belajar saya, karena di ELP saya bisa meraih impian saya",
        },
        {
          image_ext: "jpg",
          name: "SHAKILA R",
          school_origin: "SMAN 1 BKL",
          achievement: "PEND. DOKTER HEWAN - UNUD",
          comment:
            "Modul yang diberikan benar2 sesuai soal yang diujikan ditambah pembahasan pengajar2nya juga singkat tapi jelas, karena bimbel ELP saya dapat menggapai jalan menuju mimpi saya sebagai dokter",
        },
        {
          image_ext: "jpg",
          name: "RIZKY A",
          school_origin: "SMAN 2 BKL",
          achievement: "IPDN",
          comment:
            "ELP sangat membantu saya dalam memahami materi dengan efektif dan inovatif",
        },
        {
          image_ext: "jpg",
          name: "AMANDA A",
          school_origin: "SMAN 5 BKL",
          achievement: "IPDN",
          comment:
            "Seneng deh bisa belajar di ELP!! pengajarnya ramah, kompeten, dan cara mengajarnya seru dan mudah dimengerti.",
        },
        {
          image_ext: "png",
          name: "ADITYA M",
          school_origin: "SMAN 1 RL",
          achievement: "STIS",
          comment:
            "ELP sangat membantu saya belajar, pengajar hebat, stafnya seru, belajarnya ga bosen, dan yang paling penting bisa ikut tambahan sepuasnyaaa. Ga akan nyesel les di ELP",
        },
        {
          image_ext: "jpg",
          name: "DAFFA DZAKY",
          school_origin: "SMAN 6 BKL",
          achievement: "BINTARA POLRI",
          comment:
            "Terimakasih ELP…. Materinya dijelaskan dengan baik sehingga dapat dipahami dan membantu dalam persiapan ujian.",
        },
        {
          image_ext: "jpg",
          name: "WARDAH NURFAZA",
          school_origin: "SMAN 5 BKL",
          achievement: "STAN",
          comment:
            "Pengajarnya ramah, metode belajarnya mudah dipahami dan tempatnya juga nyaman. Terimakasih ELP",
        },
      ],
      swiperOptions: {
        slidesPerView: 5,
        spaceBetween: 50,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        breakpoints: {
          1024: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        },
      },
    };
  },
};
</script>

<style>
.carousel-indicators > li {
  background-color: var(--primary);
}
</style>
