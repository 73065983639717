<template>
  <div>
    <h4 class="text-center mb-5 mt-5">
      Berbagai program kami
    </h4>

    <div class="container px-3 mb-5">
      <div class="row align-items-center mb-5">
        <!-- Image Column -->
        <div class="col-md-6 d-flex image-side justify-content-center fade-in mb-3 mb-md-0">
          <img src="https://pngfre.com/wp-content/uploads/book-82-300x222.png" alt="Sample Image"
            class="custom-image img-fluid">
        </div>
        <!-- Text Column -->
        <div class="col-md-6 d-flex text-side fade-in">
          <div class="text-content">
            <h3 class="text-info">Paket Bimbel Lengkap Sertifikat & Video Pembelajaran</h3>
            <p class="text-black">✅Sertifikat Penyelesaian Dapatkan sertifikat resmi setelah menyelesaikan setiap paket
              bimbel sebagai bukti pencapaian dan kompetensi kamu.</p>
            <p class="text-black">✅Akses Video Pembelajaran Lengkap Setiap paket mencakup video pembelajaran lengkap
              yang dapat diakses kapan saja, memastikan kamu mendapatkan materi secara menyeluruh.</p>
            <p class="text-black">✅Pembayaran Sekali Bayar, Bayar sekali untuk akses penuh dan seumur hidup ke seluruh
              materi dalam paket, tanpa biaya tambahan di masa depan.</p>
          </div>
        </div>
      </div>

      <div class="row align-items-center mb-5">
        <!-- Text Column -->
        <div class="col-md-6 d-flex text-side fade-in mb-3 mb-md-0">
          <div class="text-content">
            <h2 class="text-info">E-book</h2>
            <p class="text-black">✅Belajar Fleksibel Akses berbagai materi belajar termasuk video, bahan bacaan, dan
              latihan praktis kapan saja dan di mana saja.</p>
            <p class="text-black">✅Struktur Pembelajaran Bertahap Konten disusun dari level Dasar hingga Lanjut,
              memastikan kamu memahami setiap konsep dengan baik.</p>
            <p class="text-black">✅Akses Lifetime Nikmati akses seumur hidup ke e-book, termasuk semua pembaruan dan
              materi tambahan di masa depan.</p>
          </div>
        </div>
        <!-- Image Column -->
        <div class="col-md-6 d-flex image-side justify-content-center fade-in">
          <img src="https://cdn-icons-png.flaticon.com/256/9913/9913434.png" alt="Sample Image"
            class="custom-image img-fluid">
        </div>
      </div>

      <div class="row align-items-center mb-5">
        <!-- Image Column -->
        <div class="col-md-6 d-flex image-side justify-content-center fade-in">
          <img
            src="https://png.pngtree.com/png-vector/20220708/ourmid/pngtree-rectangular-green-chalkboard-png-image_5683105.png"
            alt="Sample Image" class="custom-image img-fluid">
        </div>
        <!-- Text Column -->
        <div class="col-md-6 d-flex text-side fade-in">
          <div class="text-content">
            <h2 class="text-info">Mading</h2>
            <p class="text-black">✅Belajar Kapan Saja Nikmati fleksibilitas dalam belajar dengan artikel yang bisa
              dibaca kapan saja, tanpa batasan waktu.</p>
            <p class="text-black">✅Update Rutin Dapatkan konten terbaru dan terupdate secara berkala, sehingga kamu
              selalu mendapatkan informasi terkini.</p>
            <p class="text-black">✅Akses Gratis Semua materi di mading tersedia tanpa biaya, memungkinkan kamu untuk
              belajar tanpa beban finansial.</p>
          </div>
        </div>
      </div>
    </div>

    <section id="card-images">
      <b-overlay :show="loading">
        <b-col>
          <h4 class="text-center mt-5">
            Beragam Paket Bimbel & Try Out untuk Mencapai Tujuanmu
          </h4>
          <div class="py-1">
            <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-3">
              <div class="col" v-for="(item, i) in data" :key="i" @click="detail(item)">
                <div class="card mx-auto shadow-lg rounded-3">
                  <div class="position-relative overflow-hidden rounded-top-3">
                    <img :src="`https://api-ecourse.digma.id/${item.thumbnail}`" class="card-img-top img-fluid"
                      alt="Thumbnail" />
                  </div>
                  <div class="card-details card-body">
                    <p class="text-black font-weight-bold mt-1">{{ item.name }}</p>
                    <div class="d-flex align-items-center mb-2">
                      <i class="fa-regular fa-user text-black"></i>
                      <span class="text-black ms-1">150</span>
                      <i class="fa-regular fa-clock text-black ml-4"></i>
                      <span class="text-black ms-1">40m</span>
                    </div>
                    <hr>
                    <div class="d-flex align-items-center">
                      <span class="text-black font-weight-bold">Rp {{ item.price.toLocaleString('id-ID') }}</span>
                    </div>
                  </div>
                  <button class="card-button btn btn-outline-primary btn-sm">Beli</button>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-overlay>
    </section>

  </div>
</template>

<script>
  import {
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BButton,
    BLink,
  } from "bootstrap-vue";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

  export default {
    components: {
      BOverlay,
      BButton,
      BRow,
      BCol,
      BCard,
      BCardText,
      BCardTitle,
      BLink,
    },

    data() {
      return {
        loading: false,
        data: [],
      };
    },

    methods: {
      getColor(color) {
        let warna = "";
        if (color == "primary") {
          warna = "blue";
        } else if (color == "success") {
          warna = "green";
        } else if (color == "danger") {
          warna = "red";
        } else if (color == "info") {
          warna = "light-blue";
        } else if (color == "warning") {
          warna = "yellow";
        }
        return warna;
      },

      getData() {
        this.loading = true;
        let params = {
          orderCol: "price",
          order: "asc",
        };
        this.$store
          .dispatch("paketTryout/index", params)
          .then((res) => {
            this.loading = false;
            this.data = res.data.data.slice(0, 4);
          })
          .catch((err) => {
            this.loading = false;
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Error `,
                icon: "XIcon",
                variant: "danger",
                text: err.response.data.message,
              },
            });
          });
      },

      detail(item) {
        window.location = `paket-detail/${item.slug}`;
      },

      handleScrollAnimation(entries, observer) {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('fade-up');
            entry.target.classList.remove('fade-down');
          } else {
            entry.target.classList.add('fade-down');
            entry.target.classList.remove('fade-up');
          }
        });
      },

      setupIntersectionObserver() {
        const observer = new IntersectionObserver(this.handleScrollAnimation, {
          threshold: 0.1
        });

        document.querySelectorAll('.fade-in').forEach(element => {
          observer.observe(element);
        });
      }
    },

    mounted() {
      this.getData();
      this.setupIntersectionObserver();
    }
  };
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

  * {
    font-family: "Open Sans", sans-serif;
  }

  .background {
    background: white;
  }

.card {
  border-radius: 20px;
  background: #f5f5f5;
  padding: 1rem;
  border: 2px solid #c3c6ce;
  transition: 0.5s ease-out;
  overflow: visible;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

  .card-img-top {
    width: 100%;
    height: 180px;
    object-fit: cover;
  }

  .card-details {
    color: black;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    flex-grow: 1;
  }

  .card-details p {
    text-align: left;
    font-size: 1rem;
  }

  .card-button {
    transform: translate(-50%, 125%);
    width: 40%;
    border: none;
    background-color: #008bf8;
    color: #fff;
    font-size: 1rem;
    padding: .5rem 1rem;
    position: absolute;
    left: 50%;
    bottom: 0;
    opacity: 0;
    transition: 0.3s ease-out;
  }

  .card:hover {
    border-color: #17a2b8;
    box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.25);
  }

  .card:hover .card-button {
    transform: translate(-50%, 50%);
    opacity: 1;
    bottom: 0;
  }

  .text-title {
    font-size: 1.2rem;
    font-weight: bold;
  }

  .d-flex {
    gap: 0.5rem;
  }

  hr {
    margin: 0.5rem 0;
  }

  @media (max-width: 768px) {
    .background-header {
      height: 200px;
    }

    .text-title {
      font-size: 1.5rem;
    }

    .card-button {
      font-size: 0.875rem;
      width: auto;
    }
  }

  @media (max-width: 576px) {
    .background-header {
      height: 150px;
    }

    .text-title {
      font-size: 1.2rem;
    }

    .card-button {
      font-size: 0.75rem;
      padding: 0.5rem;
    }
  }
</style>