var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h4', {
    staticClass: "text-center mb-5 mt-5"
  }, [_vm._v(" Berbagai program kami ")]), _vm._m(0), _c('section', {
    attrs: {
      "id": "card-images"
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-col', [_c('h4', {
    staticClass: "text-center mt-5"
  }, [_vm._v(" Beragam Paket Bimbel & Try Out untuk Mencapai Tujuanmu ")]), _c('div', {
    staticClass: "py-1"
  }, [_c('div', {
    staticClass: "row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-3"
  }, _vm._l(_vm.data, function (item, i) {
    return _c('div', {
      key: i,
      staticClass: "col",
      on: {
        "click": function click($event) {
          return _vm.detail(item);
        }
      }
    }, [_c('div', {
      staticClass: "card mx-auto shadow-lg rounded-3"
    }, [_c('div', {
      staticClass: "position-relative overflow-hidden rounded-top-3"
    }, [_c('img', {
      staticClass: "card-img-top img-fluid",
      attrs: {
        "src": "https://api-ecourse.digma.id/".concat(item.thumbnail),
        "alt": "Thumbnail"
      }
    })]), _c('div', {
      staticClass: "card-details card-body"
    }, [_c('p', {
      staticClass: "text-black font-weight-bold mt-1"
    }, [_vm._v(_vm._s(item.name))]), _c('div', {
      staticClass: "d-flex align-items-center mb-2"
    }, [_c('i', {
      staticClass: "fa-regular fa-user text-black"
    }), _c('span', {
      staticClass: "text-black ms-1"
    }, [_vm._v("150")]), _c('i', {
      staticClass: "fa-regular fa-clock text-black ml-4"
    }), _c('span', {
      staticClass: "text-black ms-1"
    }, [_vm._v("40m")])]), _c('hr'), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('span', {
      staticClass: "text-black font-weight-bold"
    }, [_vm._v("Rp " + _vm._s(item.price.toLocaleString('id-ID')))])])]), _c('button', {
      staticClass: "card-button btn btn-outline-primary btn-sm"
    }, [_vm._v("Beli")])])]);
  }), 0)])])], 1)], 1)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container px-3 mb-5"
  }, [_c('div', {
    staticClass: "row align-items-center mb-5"
  }, [_c('div', {
    staticClass: "col-md-6 d-flex image-side justify-content-center fade-in mb-3 mb-md-0"
  }, [_c('img', {
    staticClass: "custom-image img-fluid",
    attrs: {
      "src": "https://pngfre.com/wp-content/uploads/book-82-300x222.png",
      "alt": "Sample Image"
    }
  })]), _c('div', {
    staticClass: "col-md-6 d-flex text-side fade-in"
  }, [_c('div', {
    staticClass: "text-content"
  }, [_c('h3', {
    staticClass: "text-info"
  }, [_vm._v("Paket Bimbel Lengkap Sertifikat & Video Pembelajaran")]), _c('p', {
    staticClass: "text-black"
  }, [_vm._v("✅Sertifikat Penyelesaian Dapatkan sertifikat resmi setelah menyelesaikan setiap paket bimbel sebagai bukti pencapaian dan kompetensi kamu.")]), _c('p', {
    staticClass: "text-black"
  }, [_vm._v("✅Akses Video Pembelajaran Lengkap Setiap paket mencakup video pembelajaran lengkap yang dapat diakses kapan saja, memastikan kamu mendapatkan materi secara menyeluruh.")]), _c('p', {
    staticClass: "text-black"
  }, [_vm._v("✅Pembayaran Sekali Bayar, Bayar sekali untuk akses penuh dan seumur hidup ke seluruh materi dalam paket, tanpa biaya tambahan di masa depan.")])])])]), _c('div', {
    staticClass: "row align-items-center mb-5"
  }, [_c('div', {
    staticClass: "col-md-6 d-flex text-side fade-in mb-3 mb-md-0"
  }, [_c('div', {
    staticClass: "text-content"
  }, [_c('h2', {
    staticClass: "text-info"
  }, [_vm._v("E-book")]), _c('p', {
    staticClass: "text-black"
  }, [_vm._v("✅Belajar Fleksibel Akses berbagai materi belajar termasuk video, bahan bacaan, dan latihan praktis kapan saja dan di mana saja.")]), _c('p', {
    staticClass: "text-black"
  }, [_vm._v("✅Struktur Pembelajaran Bertahap Konten disusun dari level Dasar hingga Lanjut, memastikan kamu memahami setiap konsep dengan baik.")]), _c('p', {
    staticClass: "text-black"
  }, [_vm._v("✅Akses Lifetime Nikmati akses seumur hidup ke e-book, termasuk semua pembaruan dan materi tambahan di masa depan.")])])]), _c('div', {
    staticClass: "col-md-6 d-flex image-side justify-content-center fade-in"
  }, [_c('img', {
    staticClass: "custom-image img-fluid",
    attrs: {
      "src": "https://cdn-icons-png.flaticon.com/256/9913/9913434.png",
      "alt": "Sample Image"
    }
  })])]), _c('div', {
    staticClass: "row align-items-center mb-5"
  }, [_c('div', {
    staticClass: "col-md-6 d-flex image-side justify-content-center fade-in"
  }, [_c('img', {
    staticClass: "custom-image img-fluid",
    attrs: {
      "src": "https://png.pngtree.com/png-vector/20220708/ourmid/pngtree-rectangular-green-chalkboard-png-image_5683105.png",
      "alt": "Sample Image"
    }
  })]), _c('div', {
    staticClass: "col-md-6 d-flex text-side fade-in"
  }, [_c('div', {
    staticClass: "text-content"
  }, [_c('h2', {
    staticClass: "text-info"
  }, [_vm._v("Mading")]), _c('p', {
    staticClass: "text-black"
  }, [_vm._v("✅Belajar Kapan Saja Nikmati fleksibilitas dalam belajar dengan artikel yang bisa dibaca kapan saja, tanpa batasan waktu.")]), _c('p', {
    staticClass: "text-black"
  }, [_vm._v("✅Update Rutin Dapatkan konten terbaru dan terupdate secara berkala, sehingga kamu selalu mendapatkan informasi terkini.")]), _c('p', {
    staticClass: "text-black"
  }, [_vm._v("✅Akses Gratis Semua materi di mading tersedia tanpa biaya, memungkinkan kamu untuk belajar tanpa beban finansial.")])])])])]);
}]

export { render, staticRenderFns }