var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "background p-3"
  }, [_vm._m(0), _c('div', {
    staticClass: "d-flex justify-content-center align-items-center flex-column"
  }, _vm._l(_vm.faqs, function (faq, index) {
    return _c('div', {
      key: index,
      staticClass: "card mb-2 clickable-card",
      staticStyle: {
        "width": "60%",
        "max-width": "900px"
      }
    }, [_c('div', {
      staticClass: "card-body",
      on: {
        "click": function click($event) {
          return _vm.toggleCollapse(index);
        }
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center justify-content-between"
    }, [_c('h5', {
      staticClass: "card-title mb-0",
      staticStyle: {
        "font-size": "1rem",
        "font-weight": "bold"
      }
    }, [_vm._v(" " + _vm._s(faq.question) + " ")]), _c('span', {
      staticStyle: {
        "cursor": "pointer"
      }
    }, [_c('i', {
      class: {
        'fas fa-chevron-down text-black': _vm.activeIndex !== index,
        'fas fa-chevron-up text-black': _vm.activeIndex === index
      }
    })])]), _c('transition', {
      attrs: {
        "name": "collapse"
      }
    }, [_vm.activeIndex === index ? _c('div', [_c('div', {
      staticClass: "text-black mt-1",
      staticStyle: {
        "font-size": "1rem"
      }
    }, [_vm._v(" " + _vm._s(faq.answer) + " ")])]) : _vm._e()])], 1)]);
  }), 0)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center mb-3"
  }, [_c('h4', [_vm._v("Yang Paling Sering Ditanyakan")])]);
}]

export { render, staticRenderFns }