<template>
  <div class="background p-3">
    <div class="text-center mb-3">
      <h4>Yang Paling Sering Ditanyakan</h4>
    </div>
    <div class="d-flex justify-content-center align-items-center flex-column">
      <div
        v-for="(faq, index) in faqs"
        :key="index"
        class="card mb-2 clickable-card"
        style="width: 60%; max-width: 900px;"
      >
        <div class="card-body" @click="toggleCollapse(index)">
          <div class="d-flex align-items-center justify-content-between">
            <h5 class="card-title mb-0" style="font-size: 1rem; font-weight: bold;">
              {{ faq.question }}
            </h5>
            <span style="cursor: pointer;">
              <i
                :class="{'fas fa-chevron-down text-black': activeIndex !== index, 'fas fa-chevron-up text-black': activeIndex === index}"
              ></i>
            </span>
          </div>
          <transition name="collapse">
            <div v-if="activeIndex === index">
              <div class="text-black mt-1" style="font-size: 1rem;">
                {{ faq.answer }}
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: null,
      faqs: [
        {
          question: "Apa yang dimaksud dengan belajar online?",
          answer: "Belajar online adalah metode pembelajaran jarak jauh dengan menggunakan bantuan teknologi. Konsep belajar ini menggunakan aplikasi belajar ataupun website untuk menyampaikan materi pelajaran.",
        },
        {
          question: "Apa keuntungan belajar online?",
          answer: "Keuntungan belajar online adalah fleksibilitas waktu dan tempat, akses ke banyak sumber daya, serta kemudahan untuk menyesuaikan gaya belajar masing-masing.",
        },
      ],
    };
  },
  methods: {
    toggleCollapse(index) {
      this.activeIndex = this.activeIndex === index ? null : index;
    },
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

* {
  font-family: "Open Sans", sans-serif;
}

.background {
  position: relative;
  background: linear-gradient(to bottom, #cce8ed, #ffffff);
}

.background::before,
.background::after {
  content: '';
  position: absolute;
  border-radius: 50%;
  background: white;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
}

.background::before {
  top: 20%;
  left: 10%;
  width: 150px;
  height: 150px;
}

.collapse-enter-active,
.collapse-leave-active {
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
  overflow: hidden;
}

.collapse-enter,
.collapse-leave-to {
  max-height: 0;
  opacity: 0;
}

.collapse-enter-to,
.collapse-leave {
  max-height: 200px;
  opacity: 1;
}

.card {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border: none;
}

.card-body {
  padding: 1px;
}

.clickable-card {
  cursor: pointer;
}

/* Responsive styling */
@media (max-width: 576px) {
  .card-body {
    padding: 10px;
  }
  
  .card-title {
    font-size: 1.1rem; 
  }
}
</style>
